import { styled } from '@linaria/react';

export const BOOK_PREVIEW_HEIGHT = 400;

export const StyledBookCoverPreview = styled.div`
  display: flex;
  justify-content: center;
  gap: var(--spacing-4xl);
  border-radius: var(--radius-round);
  background-color: var(--surface-dim);

  &[data-padding='true'] {
    padding: var(--spacing-4xl);
  }

  body[data-mobile='true'] &[data-padding='true'] {
    padding: var(--spacing-lg);
  }
`;

export const Cover = styled.div`
  display: flex;
  width: 320px;
  height: ${BOOK_PREVIEW_HEIGHT}px;
  background-color: var(--color);
  box-shadow: 6.107px 6.107px 6.107px 0px rgba(0, 0, 0, 0.1), 12.213px 6.107px 12.213px 0px rgba(0, 0, 0, 0.1),
    12.213px 10.178px 22.391px 0px rgba(0, 0, 0, 0.1);
`;

export const CoverShadow = styled.div`
  height: 100%;
  width: 16px;
  background: linear-gradient(
    90deg,
    var(--shadow-color) 17.39%,
    var(--shadow-color) 79.51%,
    rgba(0, 0, 0, 0.25) 101.84%
  );
`;

export const CoverContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 32.568px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
`;

export const CoverPhoto = styled.img`
  max-width: 176.58px;
  max-height: 123.657px;
  object-fit: cover;
`;

const titleSize = 18.32;
const titleMaxHeight = titleSize * 1.1 * 4 + 'px';
export const CoverTitle = styled.span`
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-serif);
  font-size: ${titleSize};
  line-height: 130%;
  letter-spacing: 0.275px;
  white-space: pre-line;
  word-break: break-word;
  max-height: ${titleMaxHeight};
  overflow: hidden;
  position: relative;

  &[data-has-photo='true'] {
    padding: 0.875rem;
    max-height: calc(${titleMaxHeight} + calc(0.875rem * 2));
  }
`;

export const CoverTitleDecorator = styled.div`
  width: 2.25rem;
  height: 1px;
  background: var(--text-color);
  position: absolute;

  left: 50%;
  transform: translateX(-50%);

  &[data-position='top'] {
    top: 0;
  }

  &[data-position='bottom'] {
    bottom: 0;
  }
`;

export const CoverSubtitle = styled.span`
  color: var(--text-color);
  text-align: center;
  font-family: var(--font-serif);
  font-size: 8.142px;
  line-height: 133%;
  letter-spacing: 0.122px;
`;

export const Side = styled.div`
  position: relative;
  width: 28.535px;
  height: 400px;
  padding: 16.306px 0px;
  background-color: var(--color);
  box-shadow: 6.115px 6.115px 6.115px 0px rgba(0, 0, 0, 0.1), 12.229px 6.115px 12.229px 0px rgba(0, 0, 0, 0.1),
    12.229px 10.191px 22.42px 0px rgba(0, 0, 0, 0.1);
`;

export const SideDelimiter = styled.div`
  height: calc(100% - var(--spacing-xl));
  overflow: hidden;
`;

export const SideTextContainer = styled.div`
  transform: rotate(90deg);
  display: flex;
  align-items: center;
  gap: 5.605px;
  height: 28.53px;
`;

export const SideText = styled.div`
  color: var(--text-color);
  font-family: var(--font-sans);
  font-size: 5.605px;
  line-height: 130%;
  letter-spacing: 0.561px;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const SideTextSeparator = styled.hr`
  background-color: var(--text-color);
  height: 6.176px;
  flex: 0 0 1px;
`;

export const PlaceholderPhoto = styled.img`
  width: 6rem;
`;

export const EbookBanner = styled.div`
  position: absolute;
  top: 4px;
  left: -24px;
  padding: 16px;
  background-color: var(--color-spruce-15);
  border-radius: 8px;
  border: 4px solid var(--inverse-border-weak);

  color: var(--on-primary);
  font-weight: 700;
  font-size: 40px;
  font-family: var(--font-sans);
`;
